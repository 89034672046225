import { IWPContent, IWPInfo } from '../interfaces/wp';
import fetch from 'node-fetch';
require('dotenv').config();

const WP_API: string = process.env.REACT_APP_WP_API!;

export const fetchWordpressData = async (
  type: string,
  section: string,
  skipCache: boolean
) => {
  const response = await fetch(
    `${WP_API}/${type}&_embed&per_page=100${
      skipCache ? `&timestamp=${Date.now()}` : ''
    }`
  ).then((res) => res.json());
  return response;
};

export const fetchWordpressPage = async (
  type: string,
  path: string,
  skipCache: boolean
) => {
  const response: IWPContent = await fetch(
    `${WP_API}/${type}&_embed&slug=${path}${
      skipCache ? `&timestamp=${Date.now()}` : ''
    }`
  ).then((res) => res.json());
  return response[0];
};

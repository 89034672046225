import React from 'react';
import {
  Link,
  withRouter,
  RouteComponentProps
} from 'react-router-dom';
import { History } from 'history';
import logoUrl from '../static/img/logo.svg';

interface INavBar {
  history?: History;
  shouldRender?: boolean;
}

const NavBar: React.FC<INavBar & RouteComponentProps> = ({
  history,
  shouldRender
}) => {
  return (
    <header>
        <div className="container d-flex justify-content-between align-items-center">
            <a href="https://www.helloconstellation.com/" className="logo"><img src={logoUrl} alt="" /></a>

            <div className="header-menu">
                <div className="mainmenu">
                    <nav id="menu">
                        <ul>
                            <li><a href="https://www.helloconstellation.com/about/" className="link">About Us</a></li>
                            <li className="has-dropdown">
                              <span></span>
                              <a href="#">Industries</a>
                              <ul>
                                  <li><a href="https://www.helloconstellation.com/pharma/">Pharma Industry</a></li>
                                  <li><a href="https://www.helloconstellation.com/automotive/">Automotive Industry</a></li>
                              </ul>
                          </li>
                           <li><a href="/" className="link">News</a></li>
                           <li><a href="https://www.helloconstellation.com/careers/" className="link">Careers</a></li>
                            <li><a href="https://www.helloconstellation.com/contact/" className="link">Contact Us</a></li>
                        </ul>
                    </nav>
                </div>
                <div className="header-toggle">
                    <div className="menu-toggle-inn">
                        <div id="menu-toggle">
                            <span></span><span></span><span></span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </header>
  );
};

export default withRouter(NavBar);

import React from 'react';
import logoUrl from '../static/img/logo.svg';
import linkedin from '../static/svgs/linkedin.svg';
import instagram from '../static/svgs/instagram.svg';
import facebook from '../static/svgs/facebook.svg';
import threads from '../static/svgs/threads.svg';
import twitter from '../static/svgs/twitter.svg';

const Footer: React.FC = () => {
  return (
    <footer>
        <div className="container">
            <a href="https://www.helloconstellation.com/" className="flogo">
              <img src={logoUrl} alt="" />
            </a>

            <div className="social">
                <a href="https://www.linkedin.com/company/hello-constellation/" target="_blank">
                  <img src={linkedin} alt="" />
                </a>

                <a href="https://www.instagram.com/hello.constellation/" target="_blank">
                  <img src={instagram} alt="" />
                </a>

                <a href="https://www.facebook.com/hello.constellation" target="_blank">
                  <img src={facebook} alt="" />
                </a>

                <a href="https://www.threads.net/@hello.constellation" target="_blank">
                  <img src={threads} alt="" />
                </a>

                <a href="https://twitter.com/hello_const" target="_blank">
                  <img src={twitter} alt="" />
                </a>
            </div>

            <div className="text-center">
                <p><a href="https://www.helloconstellation.com/contact/">Contact Us</a> | <a href="/careers/">Careers</a> | <a href="https://www.helloconstellation.com/privacy/">Privacy Policy</a><span>Constellation Software Inc. All Rights Reserved.</span></p>
            </div>
        </div>
    </footer>
  );
};

export default Footer;

import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import './index.css';
import './fonts/HarmoniaSansPro/HarmoniaSansPro-Bold.woff';
import './fonts/HarmoniaSansPro/HarmoniaSansPro-Bold.woff2';
import './fonts/HarmoniaSansPro/HarmoniaSansPro-Regular.woff';
import './fonts/HarmoniaSansPro/HarmoniaSansPro-Regular.woff2';
import './fonts/HarmoniaSansPro/HarmoniaSansPro-SemiBd.woff';
import './fonts/HarmoniaSansPro/HarmoniaSansPro-SemiBd.woff2';
import App from './App';
import * as serviceWorker from './serviceWorker';
import './static/canvas';

ReactDOM.render(
  <React.StrictMode>
    <BrowserRouter>
      <App />
    </BrowserRouter>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();

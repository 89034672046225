import React from 'react';
import { Link } from 'react-router-dom';
import plusIcon from '../static/img/plus-icon.svg';
import newsBg from '../static/img/news-bg.webp';
import plugStrokeIcon from '../static/img/plus-icon-white-stroke.svg';

interface INewsTileProps {
  image: string;
  title: string;
  urlSlug: string;
  datePublished: string;
  type: string;
}

const NewsTile: React.FC<INewsTileProps> = ({
  image,
  title,
  urlSlug,
  datePublished,
  type
}) => {
  const dateFormatted = datePublished.split('T')
  let newsWrap = 'news-box'
  if ( '' === image ) {
    newsWrap += ' active'
  }

  return (
    <Link to={`/${type}/${urlSlug}`} className={newsWrap}>
          <div className="image">
              {'' !== image && <img src={image} alt="news thumbnail" /> }
          </div>
        <div className="text">
            <div className="inner">
                <span className="d-block date">{dateFormatted[0]}</span>
                <span className="d-block title">{title}</span>
            </div>
            <span className="action d-flex align-items-center justify-content-end">
                Full article
                <img src={plusIcon} alt="icon" />
            </span>
        </div>
        <div className="hover" style={{background: "url(" + newsBg + ") no-repeat scroll 0 0/cover"}}>
            <div className="text">
            <div className="inner">
                <span className="d-block date">{dateFormatted[0]}</span>
                <span className="d-block title">{title}</span>
            </div>
            <span className="action d-flex align-items-center justify-content-end">
                Full article
                <img src={plugStrokeIcon} alt="icon" />
            </span>
        </div>
        </div>
    </Link>
  );
};

export default NewsTile;

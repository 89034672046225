import React from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';
import CookieConsent from 'react-cookie-consent';
import './App.css';
import { routes } from './routes';
import NavBar from './components/NavBar';
import Footer from './components/Footer';

function App() {
  return (
    <div className="wrapper app">
      <CookieConsent
        buttonText="OK"
        style={{
          background:
            'linear-gradient(rgba(21, 30, 40, .9) 30%, rgba(11, 20, 30, .8) 100%)',
          height: 'fit-content',
          fontSize: '12px',
          flexWrap: 'nowrap',
          alignItems: 'center'
        }}
        buttonStyle={{
          background: 'none',
          color: 'white',
          border: 'solid 1.5px white',
          fontWeight: 'bolder',
          borderRadius: '30px',
          margin: '12px'
        }}
      >
        This website uses cookies to improve the user experience. By continuing,
        you consent to these cookies. Learn more in our{' '}
        <a
          style={{
            fontWeight: 'bold',
            textDecoration: 'underline',
            color: 'white'
          }}
          href="https://www.helloconstellation.com/privacy/"
        >
          Privacy Policy
        </a>.
      </CookieConsent>

      <NavBar />
      <Switch>
        {routes.map((route, idx) => {
          if (route.exact) {
            return (
              <Route
                exact={route.exact}
                path={route.path}
                component={route.component}
                key={idx}
              />
            );
          } else {
            return (
              <Route
                exact={route.exact}
                path={route.path}
                key={idx}
                render={() => <route.component pageTitle={route.pageTitle!} />}
              />
            );
          }
          // // legacy routes
          // return (
          //   route.legacyRoutes &&
          //   route.legacyRoutes.map((legacy, index) => (
          //     <Route
          //       exact={true}
          //       path={legacy.replace('https://constellationagency.com', '')}
          //       key={index}
          //       component={() => {
          //         window.location.href = `${legacy.replace(
          //           'https://constellationagency.com',
          //           'https://archive.constellationagency.com'
          //         )}`;
          //         return null;
          //       }}
          //     />
          //   ))
          // );
        })}
        <Route
          path="/team"
          exact={true}
          component={() => <Redirect to="/about" />}
        />
        <Route
          path="/social-impact"
          exact={true}
          component={() => <Redirect to="/causes" />}
        />
        <Route
          path="/launchpad"
          exact={true}
          component={() => <Redirect to="/technology/dashboard" />}
        />
        <Route
          path="/paid/search"
          exact={true}
          component={() => <Redirect to="/digital-marketing" />}
        />
        <Route
          path="/paid/social"
          exact={true}
          component={() => <Redirect to="/digital-marketing" />}
        />
        <Route
          path="/roas"
          exact={true}
          component={() => <Redirect to="/roas" />}
        />
        <Route path="*" exact={true} component={() => <Redirect to="/" />} />
      </Switch>
      <Footer />
    </div>
  );
}

export default App;

import React, { useState, useEffect } from 'react';
import { fetchWordpressPage } from '../utils/wordpress';
import { useLocation, Redirect } from 'react-router-dom';
import IframeResizer from 'iframe-resizer-react';
import MetaData from '../components/MetaData';
import { Link } from 'react-router-dom';
import backIcon from '../static/img/back.svg';
import defaultImage from '../static/img/default.png';

interface ISingleNewsProps {
  pageTitle: string;
}

const SingleNews: React.FC<ISingleNewsProps> = ({ pageTitle }) => {
  // path: is second element of /route/path
  const pathArr = useLocation().pathname.split('/');
  const [windowWidth, setWindowWidth] = useState<number>(window.innerWidth);
  const breakpoint = 740; // mobile breakpoint
  const queryParam = useLocation().search;
  const [wpLink, setWpLink] = useState<string>();
  const [wpHeight, setWpHeight] = useState<number>();
  const [wpHeightMobile, setWpHeightMobile] = useState<number>();
  const [metaTitle, setMetaTitle] = useState<string>();
  const [metaDescription, setMetaDescription] = useState<string>();
  const [metaImage, setMetaImage] = useState<string>();
  const [shouldRedirect, setShouldRedirect] = useState<boolean>(false);

  useEffect(() => {
    window.scrollTo(0, 0);
    const fetchData = async () => {
      const response = await fetchWordpressPage(
        'posts',
        pathArr[2],
        queryParam === '?skipCache=true'
      );

      if (response && response.link) {
        setWpLink(response.link);
        setWpHeight(2300); // default
        setWpHeightMobile(3100); // default

        setMetaDescription(response.excerpt.rendered);
        const imageUrl = response._embedded['wp:featuredmedia'] ? response._embedded['wp:featuredmedia'][0].source_url : defaultImage

        setMetaImage(imageUrl);
        setMetaTitle(response.title.rendered);
      }

      if (
        !response &&
        pathArr[2] !==
          'constellation-agency-named-the-10th-fastest-growing-female-led-company-in-america-on-the-2020-inc.-5000-list-no.-65-overall' &&
        pathArr[2] !==
          'constellation-agencys-diana-lee-selected-for-competitive-ey-enterpreneurial-winning-women-class-of-2019'
      )
        setShouldRedirect(true);
    };
    fetchData();

    const handleWindowResize = () => setWindowWidth(window.innerWidth);
    window.addEventListener('resize', handleWindowResize);

    return () => window.removeEventListener('resize', handleWindowResize);
  }, [pathArr, queryParam]);

  return (
    <section className="news-content-area">
      <div className="container">
        {pathArr[2] ===
        'constellation-agency-named-the-10th-fastest-growing-female-led-company-in-america-on-the-2020-inc.-5000-list-no.-65-overall' ? (
          <Redirect to="/news/constellation-agency-named-the-no.-65th-fastest-growing-private-company-in-america-on-the-2020-inc.-5000-list" />
        ) : pathArr[2] ===
          'constellation-agencys-diana-lee-selected-for-competitive-ey-enterpreneurial-winning-women-class-of-2019' ? (
          <Redirect to="/news/constellation-agency%E2%80%99s-diana-lee-selected-for-competitive-ey-entrepreneurial-winning-women-class-of-2019" />
        ) : (
          shouldRedirect && <Redirect to="/news" />
        )}

        <div className="back_action">
            <Link to="/" className="back_btn d-inline-flex align-items-center">
              <img src={backIcon} alt="icon" /><span>Back all articles</span>
            </Link>
        </div>

        {wpHeight && wpHeightMobile && (
          <div>
            {metaDescription && metaTitle && metaImage && (
              <MetaData
                title={metaTitle}
                description={metaDescription}
                image={metaImage}
              />
            )}
            <IframeResizer
              src={wpLink}
              style={{ width: '1px', minWidth: '100%' }}
              checkOrigin={false}
              log={false}
              scrolling={true}
              heightCalculationMethod="grow"
              minHeight={2300}
            />
          </div>
        )}
      </div>
    </section>
  );
};

export default SingleNews;

import React, { useEffect, useState } from 'react';
import { IWPContent } from '../interfaces/wp';
import { useLocation } from 'react-router-dom';
import { fetchWordpressData } from '../utils/wordpress';
import MetaData from '../components/MetaData';
import NewsTile from '../components/NewsTile';

import './News.scss';

const News: React.FC = () => {
  const [newsContent, setNewsContent] = useState<IWPContent>();
  const queryParam = useLocation().search;

  useEffect(() => {
    window.scrollTo(0, 0);
    const fetchData = async () => {
      const response = await fetchWordpressData(
        'posts',
        'news',
        queryParam === '&skipCache=true'
      );
      setNewsContent(response);
    };
    fetchData();
  }, [queryParam]);

  return (
    <section className="news-area">
      <div className="container">
        <MetaData
          title="News"
          description="Stay in touch with Constellation's News"
          image="https://constellationinc.s3.amazonaws.com/Website/Web+Content/Constellation+Agency/2020/Artboard+1.jpg"
        />

        <div className="head">
            <h1 className="h2">Latest News</h1>
            <p>We’re excited to share with you the top stories about our activities, clients and market.</p>
        </div>

        {newsContent && (
          <div className="news-list">
            <div className="row">
              {newsContent.map(
                (
                  {
                    title,
                    slug,
                    type,
                    date,
                    _embedded: { 'wp:featuredmedia': image },
                    // custom_fields: { image, date_published }
                  },
                  idx
                ) => {
                  const imageUrl = image ? image[0].source_url : ''
                  return (
                    <div key={idx} className="col-md-4 col-sm-6">
                      <NewsTile
                        image={imageUrl}
                        title={title.rendered}
                        urlSlug={slug}
                        datePublished={date}
                        type={type}
                      />
                    </div>
                  )
                }
              )}
            </div>
          </div>
        )}
      </div>
    </section>
  );
};

export default News;

import News from './pages/News';
import SingleNews from './pages/SingleNews';
// import HomePage from './pages/Home';
// import AboutPage from './pages/About';
// import AlexiaPage from './pages/Alexia';
// import DataSolutionsPage from './pages/DataSolutions';
// import VendorataManagerPage from './pages/VendorDataManager';
// import LeadershipPage from './pages/Leadership';
// import TechnologyPage from './pages/Technology';
// import ContactPage from './pages/Contact';
// import LaunchpadPage from './pages/Launchpad';
// import DigitalMarketingPage from './pages/DigitalMarketing';
// import Careers from './pages/Careers';
// import Creative from './pages/Creative';
// import Causes from './pages/Causes';
// import Wordpress from './pages/Wordpress';
// import Work from './pages/Work';
// import Roas from './pages/Roas';
// import AutomotiveAd from './pages/AutomotiveAd';
// import CaseStudies from './pages/CaseStudies';
// import Partners from './pages/Partners';
// import Newsletter from './pages/Newsletter';
// import legacyRoutes from './legacy-routes';
// import AboutAlexia from './pages/AboutAlexia';
// import Banking from './pages/Banking';
// import Auto from './pages/Auto';
// import Pharma from './pages/Pharma';

export const routes = [
  {
    exact: true,
    path: '/',
    component: News
  },
  {
    exact: false,
    legacy: false,
    path: '/:id',
    component: SingleNews,
    pageTitle: 'NEWS'
  },
  // {
  //   exact: true,
  //   path: '/',
  //   component: HomePage
  // },
  // {
  //   exact: true,
  //   path: '/about',
  //   component: AboutPage
  // },
  // {
  //   exact: true,
  //   path: '/technology/our-platform',
  //   component: AlexiaPage
  // },
  // {
  //   exact: true,
  //   path: '/technology/data-solutions',
  //   component: DataSolutionsPage
  // },
  // {
  //   exact: true,
  //   path: '/technology/vendor-data-manager',
  //   component: VendorataManagerPage
  // },
  // {
  //   exact: true,
  //   path: '/technology/dashboard',
  //   component: LaunchpadPage
  // },
  // {
  //   exact: true,
  //   path: '/technology',
  //   component: TechnologyPage
  // },
  // {
  //   exact: true,
  //   path: '/leadership',
  //   component: LeadershipPage
  // },
  // {
  //   exact: true,
  //   path: '/contact',
  //   component: ContactPage
  // },
  // {
  //   exact: true,
  //   path: '/digital-marketing',
  //   component: DigitalMarketingPage
  // },
  // {
  //   exact: true,
  //   path: '/careers',
  //   component: Careers
  // },
  // {
  //   exact: true,
  //   path: '/creative',
  //   component: Creative
  // },  
  // {
  //   exact: true,
  //   path: '/newsletter',
  //   component: Newsletter
  // },
  // {
  //   exact: true,
  //   path: '/causes',
  //   component: Causes
  // },
  // {
  //   exact: true,
  //   path: '/roas',
  //   component: Roas
  // },
  // {
  //   exact: true,
  //   path: '/automotiveadvertising',
  //   component: AutomotiveAd
  // },
  // {
  //   exact: false,
  //   legacy: false,
  //   path: '/careers/:id',
  //   component: Wordpress,
  //   pageTitle: 'CAREERS'
  // },
  // {
  //   exact: false,
  //   legacy: false,
  //   path: '/newsletter/:id',
  //   component: Wordpress,
  //   pageTitle: 'NEWSLETTER'
  // },
  // {
  //   exact: true,
  //   legacy: true,
  //   legacyRoutes,
  //   component: Wordpress
  // },
  // {
  //   exact: true,
  //   path: '/work',
  //   component: Work
  // },
  // {
  //   exact: false,
  //   legacy: false,
  //   path: '/work/:id',
  //   component: Wordpress,
  //   pageTitle: 'WORK'
  // },
  // {
  //   exact: true,
  //   path: '/casestudies',
  //   component: CaseStudies
  // },
  // {
  //   exact: false,
  //   legacy: false,
  //   path: '/casestudies/:id',
  //   component: Wordpress,
  //   pageTitle: 'CASE STUDIES'
  // },
  // {
  //   exact: true,
  //   path: '/partners',
  //   component: Partners
  // },
  // {
  //   exact: false,
  //   legacy: false,
  //   path: '/partners/:id',
  //   component: Wordpress,
  //   pageTitle: 'CERTIFIED PROVIDER'
  // },
  // {
  //   exact: true,
  //   path: '/alexia',
  //   component: AboutAlexia
  // },
  // {
  //   exact: true,
  //   path: '/alexia-1',
  //   component: AboutAlexia
  // },
  // {
  //   exact: true,
  //   path: '/alexia-2',
  //   component: AboutAlexia
  // },
  // {
  //   exact: true,
  //   path: '/alexia-3',
  //   component: AboutAlexia
  // },
  // {
  //   exact: true,
  //   path: '/industries/banking',
  //   component: Banking
  // },
  // {
  //   exact: true,
  //   path: '/industries/banking-1',
  //   component: Banking
  // },
  // {
  //   exact: true,
  //   path: '/industries/banking-2',
  //   component: Banking
  // },
  // {
  //   exact: true,
  //   path: '/industries/banking-3',
  //   component: Banking
  // },
  // {
  //   exact: true,
  //   path: '/industries/auto',
  //   component: Auto
  // },
  // {
  //   exact: true,
  //   path: '/industries/auto-1',
  //   component: Auto
  // },
  // {
  //   exact: true,
  //   path: '/industries/auto-2',
  //   component: Auto
  // },
  // {
  //   exact: true,
  //   path: '/industries/auto-3',
  //   component: Auto
  // },
  // {
  //   exact: true,
  //   path: '/industries/pharma',
  //   component: Pharma
  // },
  // {
  //   exact: false,
  //   legacy: false,
  //   path: '/legal/:id',
  //   component: Wordpress,
  //   pageTitle: 'PRIVACY POLICY'
  // }
];

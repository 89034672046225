(function() {
    'use strict';
    var LorenzAttractor;

    Math.DEG = 180 / Math.PI;

    Math.RAD = Math.PI / 180;

    Math.TAU = Math.PI * 2;

    Math.PHI = 0.5 + 0.5 * Math.sqrt(5);

    LorenzAttractor = function(σ, ρ, β) {
      return function(v, t) {
        var x, y, z;
        [x, y, z] = v;
        v[0] += t * (σ * (y - x));
        v[1] += t * (x * (ρ - z) - y);
        return v[2] += t * (x * y - β * z);
      };
    };

    document.addEventListener('DOMContentLoaded', function() {
      var canvas, context, lorenz, model, mvp, p, projection, render, u, v, view;
      lorenz = LorenzAttractor(10, 28, 8 / 3);
      canvas = document.createElement('canvas');
      context = canvas.getContext('2d');
      document.body.appendChild(canvas);
      model = window.glMatrix.mat4.create();
      view = window.glMatrix.mat4.create();
      projection = window.glMatrix.mat4.create();
      mvp = window.glMatrix.mat4.create();
      v = window.glMatrix.vec4.fromValues(1, 0, 0, 1);
      u = window.glMatrix.vec4.create();
      p = window.glMatrix.vec4.create();
      return (render = function() {
        var H, HH, HW, T, W, gradient, i, j, lastX, lastY, outside, x, y, z;
        requestAnimationFrame(render);
        T = 1e-3 * Date.now();
        W = canvas.clientWidth;
        H = canvas.clientHeight;
        if (W !== canvas.width || H !== canvas.height) {
          canvas.width = W;
          canvas.height = H;
          context.globalCompositeOperation = 'screen';
          gradient = context.createLinearGradient(0, 0, W, H);
          gradient.addColorStop(0, 'rgba(0,0,0,1)');
          gradient.addColorStop(.5, 'rgba(11,30,66,1)');
          gradient.addColorStop(1, 'rgba(158,66,143,1)');
          context.strokeStyle = gradient;
        }
        window.glMatrix.mat4.identity(model);
        window.glMatrix.mat4.rotateY(model, model, 0.05 * T);
        window.glMatrix.mat4.rotateX(model, model, 1.89 * Math.PI);
        window.glMatrix.mat4.rotateZ(model, model, 1.66 * Math.PI);
        window.glMatrix.mat4.translate(model, model, [0, 0, -27]);
        window.glMatrix.mat4.lookAt(view, [0, 3, 25], [0, -2, 0], [0, 1, 0]);
        window.glMatrix.mat4.perspective(projection, 45 * Math.RAD, W / H, 1e-3, 1e3);
        window.glMatrix.mat4.scale(projection, projection, [HW = W / 2, HH = H / 2, 1]);
        [model, view, projection].reduce(function(a, b) {
          return window.glMatrix.mat4.mul(mvp, b, a);
        });
        context.setTransform(1, 0, 0, -1, HW, HH);
        for (i = j = 0; j < 100000; i = ++j) {
          if (i === 1e2) {
            window.glMatrix.vec3.copy(u, v);
          }
          lorenz(v, 5e-3);
          window.glMatrix.vec4.transformMat4(p, v, mvp);
          window.glMatrix.vec3.scale(p, p, 1 / p[3]);
          [x, y, z] = p;
          if ((-1 < z && z < 1) && (-HH < y && y < HH) && (-HW < x && x < HW)) {
            if (outside) {
              outside = false;
              context.moveTo(lastX, lastY);
            }
            context.lineTo(x, y);
          } else {
            if (!outside) {
              outside = true;
              context.lineTo(x, y);
            }
            lastX = x;
            lastY = y;
          }
        }
        window.glMatrix.vec3.copy(v, u);
        context.setTransform(1, 0, 0, 1, 0, 0);
        context.clearRect(0, 0, W, H);
        context.stroke();
        return context.beginPath();
      })();
    });

  }).call(this);
